<template>
  <div class="layout">
    <!-- 布局组件 -->
    <el-container>
      <el-header>
        <Header v-if="$route.path == '/login'||role==4"></Header>
        <DealerHeader v-else />
        <!-- <Header v-else></Header> -->
        <!-- <DealerHeader v-else /> -->
      </el-header>
      <el-container>
        <!-- 侧导航栏 -->
        <el-aside v-if="$route.path != '/login' && $route.path != '/password' && $route.path != '/home'" width="19.9208vw">
          <h1 v-if="role == 4">SERVICE</h1>
          <div v-if="role == 4" class="text">Inventory Management</div>
          <Aside :num="messageNumber" v-if="role != 4" :obj="info"></Aside>
        </el-aside>
        <!-- 内容 -->
        <el-main
          :style="{ padding: $route.path == '/home' ? '0' : role == null ? '60px 0 7.5vw 0' : '37px 2.6vw 120px 0' }">
          <router-view @changeNumber="getmessage"></router-view>
          <!-- 回到顶部 -->
          <el-backtop target=".layout">
            <img src="@/assets/imgs/toTop.png" alt="">
          </el-backtop>
        </el-main>
      </el-container>
    </el-container>
    <!-- 脚部 -->
    <div class="footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import Aside from "@/views/component/aside.vue";
import Header from "@/views/component/header.vue";
import DealerHeader from "@/views/component/dealerHader.vue";
import Footer from "@/views/component/footer.vue";
import local from "../../utils/local";
import { $api_unread_count, $api_menuList } from "@/api/index";
export default {
  components: {
    Aside,
    Header,
    Footer,
    DealerHeader
  },
  data() {
    return {
      role: null,
      info: {},
      messageNumber: 0,
    };
  },
  watch: {
    $route() {
      this.role = local.get("userInfo") ? local.get("userInfo").role : null;
      this.info = local.get("userInfo") ? local.get("userInfo") : {};
      this.getmessage()
    }
  },
  created() {
    this.role = local.get("userInfo") ? local.get("userInfo").role : null;
    this.info = local.get("userInfo") ? local.get("userInfo") : {};
    this.$nextTick(() => {
      this.getmessage();
    });
  },
  methods: {
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    async getmessage() {
      let res = await $api_unread_count({
        admin_id: local.get("userInfo") ? local.get("userInfo").id : ''
      });
      if (res.data.code == 200) {
        this.messageNumber = res.data.number
      }
    }
  }
};
</script>

<style lang="less" scoped>
.layout {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .el-backtop {
    background: rgba(0, 0, 0, 0);
  }
}

.el-header {
  height: auto !important;
  padding: 0;
  padding-top: 15px;
}

.el-aside {
  h1 {
    margin-left: 9.0625vw;
    color: #0f1423;
    height: 2.0313vw;
    font-size: 1.3542vw;
    font-weight: 500;
    margin-top: 2.3958vw;
  }

  .text {
    box-sizing: border-box;
    margin-left: 8.5417vw;
    width: 13.9583vw;
    min-width: 200px;
    height: 2.3438vw;
    background: #ededed;
    line-height: 2.3438vw;
    padding-left: .8854vw;
  }
}

.el-main {
  height: 100%;
  width: 100%;

  img {
    width: 38px;
    height: 38px;
    position: fixed;
    right: 40px;
    bottom: 40px;
    z-index: 9999;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
        .el-aside {
            width: 100vw !important
        }
        .el-container{
          flex-direction: column;
        }
        .el-main{
          padding: 20px !important;
        }
}
</style>
<template>
  <div>
    <div class="footer">
      <div class="box1-bg">
        <div class="list">
          <div class="box1">
            <img src="./images/footer-icon.png" />
            <img src="./images/footer-icon-2.png" />
            <img src="./images/footer-icon-3.png" />
          </div>
        </div>
      </div>
      <div class="list">
        <div class="box2 cf">
          <div class="box2L">
            <img class="im" src="./images/header-logo-img.png" />
            <div class="brator-social-link svg-link">
              <a href="https://www.facebook.com/AODESUSA">
                <img src="@/assets/imgs/abouticon (1).svg" alt="">
              </a>
              <a href="https://twitter.com/aodesusa">
                <img src="@/assets/imgs/abouticon (3).svg" alt="">
              </a>
              <a href="https://www.instagram.com/aodesusa/">
                <img src="@/assets/imgs/abouticon (2).svg" alt="">
              </a>
              <a href="https://www.youtube.com/channel/UC2Xal2KhMYeHc8I3DqgD-1g">
                <img src="@/assets/imgs/abouticon (4).svg" alt="">
              </a>
              <a href="https://www.tiktok.com/@aodesusa">
                <img src="@/assets/imgs/TikTok.png" alt="">
              </a>
            </div>
          </div>
          <div class="box2R">
            <div class="li">
              <div class="footer-top-title">ABOUT AODES</div>
              <div class="brator-link-list-one">
                <ul>
                  <li><a href="https://aodes.com/news/">News</a></li>
                  <li><a href="https://aodes.com/about-us/">Our Story</a></li>
                  <li><a href="https://aodes.com/privacy-policy/">Privacy Policy</a></li>
                </ul>
              </div>
            </div>
            <div class="li">
              <div class="footer-top-title">QUICK LINKS</div>
              <div class="brator-link-list-one">
                <ul>
                  <li><a href="https://aodes.com/stores/">Dealer Locator</a></li>
                  <li><a href="https://aodes.com/my-account/">Parts</a></li>
                  <li><a href="#">Service</a></li>
                  <li><a href="/download-center/">Download Center</a></li>
                </ul>
              </div>
            </div>
            <div class="li">
              <div class="footer-top-title">CUSTOMER CARE</div>
              <div class="brator-link-list-one">
                <ul>
                  <li><a href="https://aodes.com/faqs/">FAQs</a></li>
                  <li><a href="https://aodes.com/my-account/">Dealer Login</a></li>
                  <li><a href="https://aodes.com/contact/">Contact us</a></li>
                  <li><a href="https://aodes.com/become-a-dealer/">Become a Dealer</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box3 cf">
        <div class="list">
          <div class="box3L">
            <p>Copyright © {{ new Date().getFullYear() }} AODES.</p>
          </div>
          <div class="box3R">
            <a href="#">
              <img src="https://aodes.solutiontrackers.com/wp-content/uploads/2022/09/visa-1.png" />
            </a>
            <a href="#">
              <img src="https://aodes.solutiontrackers.com/wp-content/uploads/2022/09/paypal-1.png" />
            </a>
            <a href="#">
              <img src="https://aodes.solutiontrackers.com/wp-content/uploads/2022/09/amex.png" />
            </a>
            <a href="#">
              <img src="https://aodes.solutiontrackers.com/wp-content/uploads/2022/09/master-card.png" />
            </a>
            <a href="#">
              <img src="https://aodes.solutiontrackers.com/wp-content/uploads/2022/09/discover.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@charset "utf-8";

html {
  width: 100%;
  margin: 0;
  padding: 0;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

body {
  max-width: 1920px;
  margin: 0 auto;
  font-family: "PingFang SC", "微软雅黑", Arial, "PingFangSC-Light",
    "Helvetica Neue", Helvetica, "Microsoft Yahei", "Hiragino Sans GB", tahoma,
    SimSun, sans-serif;
  /*font-family:"Microsoft YaHei", Helvetica, Arial, "sans-serif";*/
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: #666666;
  line-height: 200%;
  background: none;
}

input,
select {
  font-family: "PingFang SC", "微软雅黑", Arial, "PingFangSC-Light",
    "Helvetica Neue", Helvetica, "Microsoft Yahei", "Hiragino Sans GB", tahoma,
    SimSun, sans-serif;
}

img {
  border: none;
  max-width: 100%;
}

dl,
dt,
dd {
  display: block;
  margin: 0;
}

p,
ul,
li,
form,
h1,
h2,
h3,
h4,
h5,
h6,
dl,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}

input::-webkit-input-placeholder {
  color: #999;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999;
}

// a {
//   color: #666666;
//   text-decoration: none !important;
//   cursor: pointer;
//   background: transparent;
//   outline: none;
//   blr: expression(this.onFocus=this.blur());
//   -webkit-transition: 0.5s;
//   -moz-transition: 0.5s;
//   -ms-transition: 0.5s;
//   -o-transition: 0.5s;
//   transition: 0.5s;
// }

a img {
  border: none;
}

* {
  box-sizing: border-box;
}

.cf:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.wrap {
  width: 66.6667vw;
  margin: 0px auto;
}

.clear {
  clear: both;
  margin: 0;
  padding: 0;
  height: 0;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.header {
  width: 100%;
}

.header .box1 {
  background: #fff;
  padding: 1.5625vw 5%;
}

.header .box1 .box1L {
  width: 25%;
  float: left;
}

.header .box1 .box1C {
  width: 48%;
  float: left;
}

.header .box1 .box1R {
  width: 27%;
  float: right;
}

.header .box1 .box1L img {
  max-width: 12.2917vw;
  display: block;
}

.header .box1 .box1L a {
  display: inline-block;
}

.brator-search-area {
  width: 96%;
  height: 2.3958vw;
  line-height: 2.3958vw;
  border-radius: .2083vw;
  background: #f2f2f7;
  border: 1px solid #d9d9d9;
  overflow: hidden;
  margin-top: .625vw;
}

.brator-search-area .search-form {
  height: 100%;
}

.brator-search-area .search-form button {
  float: left;
  width: 9%;
  height: 100%;
  border: 0;
  background: #f2f2f7;
}

.header .box1 .box1C .search-field {
  width: 91%;
  display: block;
  border: 0;
  background: #f2f2f7;
  height: 2.2917vw;
  line-height: 2.2917vw;
  float: right;
  padding-left: .5208vw;
  outline: none;
}

.qty::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.brator-header-area .col-xxl-6.col-xl-5.lg-dextop-none {
  padding-right: 0px;
}

.brator-search-area .search-form button svg {
  font-size: 1.3542vw;
}

.cart_tag {
  float: right;
  position: relative;
  margin-left: 1.0417vw;
}

.login-buton {
  float: right;
}

.login {
  background-color: #000;
  color: #fff;
  padding: 0 1.4063vw;
  height: 2.8125vw;
  line-height: 2.8125vw;
  display: inline-block;
  text-decoration: none;
  transform: skewX(-10deg);
  font-size: .7813vw;
  text-transform: uppercase;
  transition: all 0.5s ease-in-out;
  border: 1px solid transparent;
  margin-top: .3646vw;
  font-family: "Montserrat";
}

.brator-header-area.header-one .brator-info-right a {
  background: #000;
  color: #fff !important;
  padding: .5208vw .5208vw;
}

.brator-header-area.header-one .brator-info-right a.login {
  padding: .5208vw .7813vw;
  margin-left: 1.0417vw;
  margin-top: 0;
}

.brator-header-area.header-one .brator-info-right .click-item-count {
  float: left;
}

.click-item-count {
  margin-right: .2604vw;
  position: relative;
  width: 1.5104vw;
  height: 1.4583vw;
}

.header-cart-total {
  display: block;
  background: url(./images/icon01.png) no-repeat right center;
  height: 2.8125vw;
  padding-right: 1.8229vw;
}

.header-cart-count {
  background: #f00;
  border-radius: 50%;
  color: var(--color-white);
  position: absolute;
  width: 1.0417vw;
  height: 1.0938vw;
  line-height: 1.0417vw;
  font-size: .5729vw;
  text-align: center;
  right: 2.0833vw;
  top: .2604vw;
}

.header .box2 {
  clear: both;
  width: 100%;
  background: #ed3333;
  color: #fff;
  line-height: 2.0833vw;
  height: 2.0833vw;
  font-size: 14px;
}

.header .box2 ul {
  width: 100%;
  height: 2.0833vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .box2 ul li {
  height: 100%;
  display: inline-block;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.header .box2 a {
  color: #fff;
  width: 100%;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 10px;
}

/*.header .box2 ul li:hover {background:#333;}*/
/*.header .box2 .more {background: url(../images/icon02.png) no-repeat 97% center; padding-right: 15px; background-size: 5px;}
.header .box2 .more:hover {background:#333 url(../images/icon02.png) no-repeat 97% center; background-size: 5px;}*/

/* MENU */
#nav {
  margin: 0;
  padding: 0;
}

#nav li a,
#nav li {
  float: left;
}

#nav li {
  list-style: none;
  position: relative;
}

#nav li a {
  text-decoration: none;
  color: white;
}

#nav li a:hover {
  background: #dddddd;
}

#nav li a.mega-menu-link1:hover {
  background: #333333;
}

#nav ul li.more ul li {
  background: #f1f1f1;
  color: #333;
}

#nav ul li.more ul li a {
  color: #333;
}

/* Submenu */
.hasChildren {
  position: absolute;
  width: .4688vw;
  height: .3125vw;
  background: url(./images/icon02.png) no-repeat 97% center;
  right: 0;
  bottom: .8333vw;
  background-size: .3125vw;
}

#nav li.more .mega-menu-link1 {
  padding-right: 1.0417vw;
}

#nav li.more .mega-menu-link1 .hasChildren {
  right: 8px;
}

#nav ul .mega-menu-link .hasChildren {
  background: url(./images/icon02-on.png) no-repeat 90% center;
  background-size: 6px;
  right: .7292vw;
}

#nav li ul .hasChildren {
  background: url(./images/icon02.png) no-repeat 97% center;
  /*border-color: #f1f1f1 #f1f1f1 #f1f1f1 #666666;*/
  bottom: .7813vw;
  right: .2604vw;
}

#nav li ul {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  padding: 0;
  margin: 0;
}

#nav li:hover>ul {
  display: block;
}

#nav li ul li,
#nav li ul li a {
  float: none;
}

* html #nav li ul li {
  display: inline;
  /* for IE6 */
}

#nav li ul li a {
  width: 16.6667vw;
  display: block;
}

#nav li ul li a.mega-menu-link2 {
  width: 9.375vw;
}

/* SUBSUB Menu */
#nav li ul li ul {
  display: none;
}

#nav li ul li:hover ul {
  left: 100%;
  top: 0;
}

.footer {
  clear: both;
  width: 100%;
  background: url(./images/foote-bg.png) no-repeat center center;
  background-size: cover;
}

.footer .list {
  width: 70vw;
  margin: 0 auto;
  overflow: hidden;
}

.footer .box1-bg {
  width: 90%;
  margin: 0 auto;
  background-image: url(./images/foote-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 5%;
}

.footer .list .box1 {
  clear: both;
  border-bottom: 1px solid #999;
  padding-bottom: 3.9063vw;
  margin-bottom: 3.125vw;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  img {
    width: 33%;
    height: auto;
  }
}

.footer .list .box2 {
  clear: both;
  padding-bottom: 6%;
}

.footer .list .box2 .box2L {
  width: 30%;
  float: left;
}

.footer .list .box2 .box2R {
  width: 65%;
  float: right;
}

.footer .list .box2 .box2L .im {
  display: block;
  margin-bottom: 1.8229vw;
  width: 9.9479vw;
  height: auto;
}

svg {
  width: 1em;
  height: 1em;
  font-size: 1.3542vw;
}

.brator-social-link {
  justify-content: center !important;
}

.brator-social-link a {
  margin-right: 5px;
}

.brator-social-link a svg {
  font-size: 2.0833vw !important;
  color: #fff !important;
}

.e-fab-facebook-f {
  background-color: #1c4b8c;
  fill: #fff !important;
  padding: 1px 13px;
  border-radius: 100%;
}

.e-fab-twitter {
  background-color: #54abee;
  fill: #fff !important;
  padding: 1px 8px;
  border-radius: 100%;
}

.e-fab-instagram {
  background: #f09433;
  background: -moz-linear-gradient(300deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  background: -webkit-linear-gradient(300deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  background: linear-gradient(300deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  fill: #fff !important;
  padding: 1px .5208vw;
  border-radius: 100%;
}

.e-fab-youtube {
  background-color: #e02f2f;
  fill: #fff !important;
  padding: 1px .5208vw;
  border-radius: 100%;
}

.footer .list .box2 .box2R .li {
  width: 33.333%;
  float: left;
}

.footer .list .box2 .box2R .li .footer-top-title {
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
  font-size: .9375vw;
  margin-bottom: 1.3021vw;
}

.footer .list .box2 .box2R .li .brator-link-list-one ul li {
  margin-bottom: .9375vw;
}

.footer .list .box2 .box2R .li .brator-link-list-one a {
  color: #666;
  font-size: .7292vw;
}

.footer .box3 {
  clear: both;
  background-image: url(./images/foote-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2.6042vw 0;
}

.footer .box3 .list {
  height: auto;
  display: flex;
  align-items: center;
}

.footer .box3 .box3L {
  width: 55%;
  float: left;
  line-height: 2.0833vw;

  p {
    font-size: 12px;
  }
}

.footer .box3 .box3R {
  width: 65%;
  float: right;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.footer .box3 .box3R a {
  margin-left: 1.5625vw;
  flex: 1;

  img {
    height: 2.0833vw;
    width: auto;
  }
}

.brator-payment-title {
  line-height: 1.0417vw;
  font-size: .7292vw;
  color: #666;
  margin-right: 1.5625vw;
  font-weight: bold;
}

@media (max-width: 1440px) {
  .footer .list {
    width: 70vw;
  }

  .footer .list .box2 .box2R .li .footer-top-title {
    font-size: 14px;
  }

  .footer .list .box2 .box2R .li .brator-link-list-one a {
    font-size: 12px;
  }
  .login {
    padding: 0 .8854vw;
  }
}
@media (max-width: 768px) {
  .footer .list{
    width: 93vw;
  }
  .footer .box1-bg{
    width: 90%;
  }
}
</style>